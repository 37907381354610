import React, { useEffect, useState } from 'react';
import { GoLocation, GoPlus, GoPin } from 'react-icons/go'
import item1Image from 'images/item1.png';
import logoIcon from 'images/logo-icon.png'

import avatar1 from 'images/avatars/1.png'
import avatar2 from 'images/avatars/2.png'
import avatar3 from 'images/avatars/3.png'
import { Dialog } from '@headlessui/react';

function Sidebar() {
    const [selectedLand, setSelectedLand] = useState([])
    let [isOpen, setIsOpen] = useState(false)

    window.sidebarData = (data) => {
        setSelectedLand(data);
    }

    useEffect(() => {
        console.log('selected land changed');
        console.log(selectedLand);
    }, [selectedLand]);
  

  return (
      <>
      <Dialog as="div" className="fixed inset-0 flex items-center justify-center w-3/5 md:w-2/6 mx-auto z-40" open={isOpen} onClose={() => setIsOpen(false)}>
            <Dialog.Overlay />
      
            <div className="bg-white text-black text-center p-5 rounded-lg">
                <Dialog.Title className="font-semibold">Demo</Dialog.Title>
                <p className="m-3">
                    We are currently testing the demo version.
                </p>
        
                <button className="p-3 bg-secondary text-white rounded-lg hover:opacity-90 transition-opacity" onClick={() => setIsOpen(false)}>Close</button>
            </div>
          </Dialog>
    <div className="w-full md:w-1/5 flex-auto bg-[#051535] p-6 flex flex-col space-y-5 overflow-scroll min-h-[50%] md:min-h-0">
        {/* <label class="relative bg-transparent block">
            <svg class="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3 fill-[#7696D4]" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                <path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z"/>
            </svg>
            <input type="email" name="email" id="email" placeholder="Search" class="form-input rounded-lg py-[0.62rem] px-4 text-md bg-transparent border border-[#7696D4] placeholder-[#7696D4] text-gray-500 appearance-none w-full block pl-12 focus:outline-none"/>
        </label> */}

        {
            (!selectedLand?.lat) && 
            (
                <span className="mx-auto">
                    Please Select a land!
                </span>
            )
        }
        <div className="flex flex-col space-y-4">
            {
                selectedLand?.lat && 
                (
                    <div>
                        <span className="font-bold">Land Details</span>
                        <div className="flex flex-row space-x-1 text-secondary items-center py-1">
                            <GoLocation />
                            <span className="text-sm">{parseFloat(selectedLand.lat).toFixed(6)} {selectedLand.lng.toFixed(6)}</span>
                        </div>
                        {selectedLand?.data && (
                            <div className="relative p-2 mt-4 bg-[#00194A]">
                                <div className="absolute flex flex-col pl-4 top-1/2 transform -translate-y-1/2">
                                    <span>OWNER</span>
                                    <span className="font-bold">{selectedLand.data.transactions[0]?.from}</span>
                                </div>
                                <img className="mx-auto w-full" src={item1Image} />
                            </div>
                        )}
                    </div>
                )
            }
            {
                selectedLand?.lat && 
                (
                    <>
                    {
                        (!selectedLand?.data || selectedLand.data.type == 2) &&
                        (
                            <>
                            <div className="px-4">
                                <span className="font-bold">Total</span>
                                <div className="flex flex-row space-x-2 items-center">
                                    <img src={logoIcon} className="h-4" />
                                    <span className="text-secondary">35,000.00</span>
                                    <span className="text-secondary opacity-50">0.95 USDT</span>
                                </div>
                            </div>
                            <div>
                                <button onClick={() => {setIsOpen(!isOpen)}} className="h-12 rounded-lg bg-[#2069FF] w-full flex justify-between items-center px-3 hover:opacity-90 transition-opacity">
                                    <span className="font-bold">Buy Place</span>
                                    <img src={logoIcon} className="h-5" />
                                </button>
                            </div>
                            <div className="flex flex-row space-x-6">
                                <div className="h-14 w-14 rounded-lg border-[1.5px] border-[#265ED1] cursor-pointer">
                                    <img src={avatar1} className="rounded-lg"/>
                                </div>
                                <div className="h-14 w-14 rounded-lg border-[1.5px] border-[#002969] cursor-pointer">
                                    <img src={avatar2}/>
                                </div>
                                <div className="h-14 w-14 rounded-lg border-[1.5px] border-[#002969] cursor-pointer">
                                    <img src={avatar3}/>
                                </div>
                                <div className="h-14 w-14 flex items-center bg-[#1B1B1B] rounded-lg border-[1.5px] border-[#002969] cursor-pointer">
                                    <GoPlus style={{margin: "0 auto"}}/>
                                </div>
                            </div>
                            <div>
                                <button onClick={() => {setIsOpen(true)}} className="w-full h-12 bg-[#042363] rounded-lg flex justify-between items-center px-3 text-secondary hover:opacity-90 transition-opacity">
                                    <span className="text-white font-bold">Pin Your Avatar</span>
                                    <GoPin />
                                </button>
                            </div>
                            </>
                        )
                    }
                    
                    <div>
                        <span className="text-[#2A4476]">ALL TRANSACTIONS</span>
                        <hr className="border-[#042363] m-3" />

                        {
                            (selectedLand.data && selectedLand.data.transactions) &&
                            (
                                <>
                                {
                                    selectedLand.data.transactions.map((tx) => (
                                        <div className="flex flex-col space-y-1">
                                            <div className="flex flex-row space-x-2 items-center">
                                                <img src={logoIcon} className="h-4" />
                                                <span className="text-secondary">35,000.00</span>
                                                <span className="text-secondary opacity-50">0.95 USDT</span>
                                            </div>
                                            <div className="p-3 border border-[#042363] rounded-lg flex justify-between items-center">
                                                <div className="flex flex-col space-y-1">
                                                    <span className="text-secondary">From</span>
                                                    <span>{tx.from}</span>
                                                </div>
                                                <div className="flex flex-col space-y-1">
                                                    <span className="text-secondary">To</span>
                                                    <span>{tx.to}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                </>
                            )
                        }
                        
                    </div>
                    </>
                )
            }
        </div>
    </div>
    </>
  );
}

export default Sidebar;
