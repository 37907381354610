import Header from 'components/Header';
import MapSection from 'components/MapSection';
import Sidebar from 'components/Sidebar';
import logo from './logo.svg';

function App() {
  return (
    <div className="App flex flex-col h-screen">
      <Header />
      <div className="w-full flex flex-col md:flex-row flex-1">
        <Sidebar />
        <MapSection />
      </div>
    </div>
  );
}

export default App;
